export default function ShareIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1 7.45C12.1657 7.45 11.3032 7.09063 10.6922 6.51563L7.31411 8.20469C7.31411 8.3125 7.35005 8.45625 7.35005 8.6C7.35005 8.77969 7.31411 8.8875 7.31411 9.03125L10.6922 10.7203C11.3032 10.1453 12.1657 9.75 13.1 9.75C15.0047 9.75 16.55 11.2953 16.55 13.2C16.55 15.1047 15.0047 16.65 13.1 16.65C11.1954 16.65 9.65005 15.1047 9.65005 13.2C9.65005 13.0563 9.65005 12.9125 9.65005 12.8047L6.27192 11.1156C5.66099 11.6906 4.79849 12.05 3.90005 12.05C1.99536 12.05 0.450048 10.5047 0.450048 8.6C0.450048 6.69531 1.99536 5.15 3.90005 5.15C4.79849 5.15 5.66099 5.54531 6.27192 6.12031L9.65005 4.43125C9.65005 4.2875 9.65005 4.14375 9.65005 4C9.65005 2.09531 11.1954 0.55 13.1 0.55C15.0047 0.55 16.55 2.09531 16.55 4C16.55 5.90469 15.0047 7.45 13.1 7.45Z"
        fill="#065444"
      />
    </svg>
  );
}
